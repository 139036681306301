import React from "react";
import Typed from "react-typed";
// import Animate from "react-smooth";
// import Particles from "react-particles-js";
// import particles from "../const/particle.js";
import { Helmet } from "react-helmet";
import "../style.css";

class Intro extends React.Component {
	render() {
		return (
			<div id="home" className="intro route bg-image intro-header">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Justin Holmes</title>
				</Helmet>

				{/* <Animate to=".5" from="0" attributeName="opacity">
					<Particles params={particles} className="particle" />
				</Animate> */}

				<div className="intro-content display-table">
					<div className="table-cell">
						<div className="container">
							<h1 className="intro-title mb-4">
								Hello, I am Justin Holmes
							</h1>
							<p className="intro-subtitle">
								<span className="text-slider-items"></span>
								<strong className="text-slider">
									<Typed
										strings={[
											"Engineer",
											"Builder",
											"Entrepreneur",
											"Student",
										]}
										typeSpeed={80}
										backDelay={1100}
										backSpeed={30}
										loop
									/>
								</strong>
							</p>
							{/* <p className="pt-3">
								<a
									className="btn btn-primary btn js-scroll px-4"
									href="#projects"
									role="button"
								>
									View My Projects
								</a>
							</p> */}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Intro;
