import React, { Suspense } from "react";
import Lottie from "react-lottie";
import Loading from "./Loading";

const DisplayLottie = ({ animationData }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
	};

	return (
		<Suspense fallback={<Loading />}>
			<Lottie
				isClickToPauseDisabled
				options={defaultOptions}
				style={{ cursor: "default" }}
			/>
		</Suspense>
	);
};

export default DisplayLottie;
