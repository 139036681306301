// import emoji from "react-easy-emoji";

import vanguard from "./assets/common/vanguard.jpg";
import fidelity from "./assets/common/fidelity.svg";
// import duke from "./assets/common/duke.svg";
import hap from "./assets/common/hap.jpeg";
import stapleHealth from "./assets/common/staple.png";
import acquira from "./assets/common/acquira.svg";
import ohmd from "./assets/common/ohmd.svg";

import purpleRain from "./assets/projects/purpleRain.png";
import selfAvoidingWalk from "./assets/projects/self-avoiding-walk.png";
import audioVisualizer from "./assets/projects/audioVisualizer.png";

export const skillsSection = {
	title: "Tools and Skills",
	// subTitle:
	// 	"Subtitle",
	skills: [
		// emoji(
		// 	"⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
		// ),
		// emoji(
		// 	"⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"
		// ),
		// emoji(
		// 	"⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
		// ),
	],

	softwareSkills: [
		{
			skillName: "Python",
			fontAwesomeClassname: "vscode-icons:file-type-python",
		},
		{
			skillName: "AWS",
			fontAwesomeClassname: "vscode-icons:file-type-aws",
		},
		// {
		// 	skillName: "Jupyter",
		// 	fontAwesomeClassname: "vscode-icons:file-type-jupyter",
		// },
		{
			skillName: "React",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		// {
		// 	skillName: "Swift",
		// 	fontAwesomeClassname: "vscode-icons:file-type-swift",
		// },
		// {
		// 	skillName: "C",
		// 	fontAwesomeClassname: "logos:c",
		// },
		// {
		// 	skillName: "CSS",
		// 	fontAwesomeClassname: "logos:css-3",
		// },
		// {
		// 	skillName: "HTML5",
		// 	fontAwesomeClassname: "logos:html-5",
		// },
		{
			skillName: "Xcode",
			fontAwesomeClassname: "vscode-icons:file-type-xcode",
		},
		{
			skillName: "SQL",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "Firebase",
			fontAwesomeClassname: "logos:firebase",
		},
		{
			skillName: "Github",
			fontAwesomeClassname: "logos:github",
		},
		{
			skillName: "Java",
			fontAwesomeClassname: "vscode-icons:file-type-java",
		},
		// {
		// 	skillName: "RStudio",
		// 	fontAwesomeClassname: "vscode-icons:file-type-r",
		// },
		{
			skillName: "Excel",
			fontAwesomeClassname: "vscode-icons:file-type-excel",
		},
		{
			skillName: "Powerpoint",
			fontAwesomeClassname: "vscode-icons:file-type-powerpoint",
		},
		{
			skillName: "Blender",
			fontAwesomeClassname: "logos:blender",
		},
		{
			skillName: "Photoshop",
			fontAwesomeClassname: "vscode-icons:file-type-photoshop",
		},
		{
			skillName: "Premiere",
			fontAwesomeClassname: "simple-icons:adobepremierepro",
		},
		{
			skillName: "Figma",
			fontAwesomeClassname: "logos:figma",
		},
		// {
		// 	skillName: "JavaScript",
		// 	fontAwesomeClassname: "logos:javascript",
		// },
		{
			skillName: "Illustrator",
			fontAwesomeClassname: "simple-icons:adobeillustrator",
		},
		{
			skillName: "Communication",
			fontAwesomeClassname: "emojione:speaking-head",
		},
		{
			skillName: "Organization",
			fontAwesomeClassname: "eos-icons:organization-outlined",
		},
		{
			skillName: "Leadership",
			fontAwesomeClassname: "fluent:people-team-16-filled",
		},
		// {
		// 	skillName: "Dronography",
		// 	fontAwesomeClassname: "icon-park:drone",
		// },
	],
};

export const SkillBars = [
	{
		Stack: "Python", //Insert stack or technology you have experience in
		progressPercentage: "90", //Insert relative proficiency in percentage
	},
	{
		Stack: "Swift",
		progressPercentage: "70",
	},
	{
		Stack: "Java",
		progressPercentage: "60",
	},
	{
		Stack: "React",
		progressPercentage: "70",
	},
];

export const experience = [
	{
		role: "Application Development Intern",
		company: "The Vanguard Group",
		companylogo: vanguard,
		companyLink: "https://investor.vanguard.com/home",
		date: "June 2022 - December 2022",
		desc: "Application development intern working on equity modernization for index rebalance periods. Using AWS, Python, React, Node, and Typescript to create a tool projected to save more than $170 million over five years. Product is used by portfolio managers across offices in the United States, the United Kingdom, and Australia.",
		// descBullets: ["Skill 1", "Skill 2", "Skill 3"],
	},
	// {
	// 	role: "Undergraduate TA",
	// 	company: "Duke University",
	// 	companylogo: duke,
	// 	date: "August 2021 - December 2021",
	// 	desc: "Lorem ipsum dolor sit amet, consefdctetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
	// 	descBullets: ["Skill 1", "Skill 2", "Skill 3"],
	// },
	{
		role: "Data Strategy Intern",
		company: "Fidelity",
		companylogo: fidelity,
		companyLink: "https://www.fidelity.com/",
		date: "June 2021 – August 2021",
		desc: "Developed a tool in Python to extract financial data from fund return statements. Tool automatically identifies tables, isolates data, and downloads output for uploaded PDF files. Presented project to C-Suite executives of business unit. Tool will be used to extract data for reporting to senior management.",
		// descBullets: ["Python", "Skill 2", "Skill 3"],
	},
	{
		role: "Private Equity Technical Intern",
		company: "Acquira",
		companylogo: acquira,
		companyLink: "https://acquira.com/",
		date: "August 2020 – February 2021",
		desc: "Automated sections of Acquisition Entrepreneur training. Developed a web-scraping program to collect business information and populate a database for an off-market business pool. Monitored company email for new clients, onboarded new customers, and improved user relationships via frequent communication.",
		// descBullets: ["Python", "Web-Scraping", "Private Equity"],
	},
	{
		role: "Data Science Intern",
		company: "Staple Health (Acquired, 2020)",
		companylogo: stapleHealth,
		companyLink:
			"https://www.mobihealthnews.com/news/unite-us-acquires-staple-health-beef-its-predictive-sdoh-analytics",
		date: "June 2019 – August 2019",
		desc: "Collected, cleaned, and verified the integrity of data used for predictive modeling. Performed analyses on various machine learning techniques to predict health outcomes. Assessed and reported the accuracy of predictive models developed from new data sources.",
		// descBullets: ["Python", "Machine Learning", "Presentating"],
	},
	{
		role: "SEO and Content Creation Intern",
		company: "OhMD",
		companylogo: ohmd,
		companyLink: "https://www.ohmd.com/",
		date: "June 2019 – November 2019",
		desc: "Researched and identified keywords to drive organic traffic to OhMD.com. Investigated and analyzed competitors' high-value content. Improved OhMD's existing content with metadata updates. Created original content to promote OhMD's product value.",
		// descBullets: [
		// 	"Search Engine Optimization",
		// 	"Written Communication",
		// 	"Startup Experience",
		// ],
	},
	{
		role: "Founder",
		company: "Holmes Aerial Photography",
		companylogo: hap,
		companyLink: "https://www.holmesaerialphotography.com/",
		date: "November 2016 – Present",
		desc: "Obtained an FAA-certified pilot’s license to fly drones commercially. Company is licensed in Vermont and North Carolina and fully-insured; offers aerial imagery to corporations, small businesses, real estate agents, and individuals looking for new ways to promote their products.",
		// descBullets: [
		// 	"Aerial Photography",
		// 	"Marketing",
		// 	"Working With Clients",
		// ],
	},
];

export const projects = [
	{
		name: "Audio Visualizer",
		img: audioVisualizer,
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
		github: "https://github.com/holmesjustin/audio-visualizer",
		link: "https://holmesjustin.github.io/audio-visualizer/",
	},
	{
		name: "Self-Avoiding Walk",
		img: selfAvoidingWalk,
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
		link: "https://holmesjustin.github.io/self-avoiding-walk/",
		github: "https://github.com/holmesjustin/self-avoiding-walk",
	},
	{
		name: "Purple Rain",
		img: purpleRain,
		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
		link: "https://holmesjustin.github.io/purple-rain/",
		github: "https://github.com/holmesjustin/purple-rain",
	},
];
