import React from "react";
import { experience } from "../portfolio";
import { Container, Row } from "reactstrap";

import { Fade } from "react-reveal";

import ExperienceCard from "../components/ExperienceCard";

const Experience = () => {
	return (
		<section id="experience" className="section section-lg">
			<Container>
				<Fade left duration={1000} distance="40px">
					<div className="p-4">
						<div className="text-center my-5">
							<h1 className="leftUnderline">
								Technical Experience
							</h1>
						</div>
					</div>
					<Row className="row-grid align-items-center">
						{experience.map((data, i) => {
							return <ExperienceCard key={i} data={data} />;
						})}
					</Row>
				</Fade>
			</Container>
		</section>
	);
};

export default Experience;
