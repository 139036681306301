import React from "react";
// import DisplayLottie from "../components/DisplayLottie";
// import floating from "../assets/lottie/floating.json";
import headshot from "../assets/common/JustinHeadshot.jpg";

class About extends React.Component {
	constructor() {
		super();
		this.state = {
			about_me: [
				{
					id: "first-p-about",
					content:
						"My name is Justin, and I'm proud to call myself a nerd.",
				},
				{
					id: "second-p-about",
					content:
						"I am a rising senior at Duke University, studying Computer Science, Economics, and Innovation & Entrepreneurship. I am most excited about utilizing technology to create new products, improve processes, and make lives easier.",
				},
				{
					id: "third-p-about",
					content:
						"I have worked in full-stack software development, data engineering, SEO, creative marketing, and graphic design across a variety of industries including asset management, private equity, healthcare, and sports.",
				},
			],
		};
	}

	render() {
		return (
			<div className="about-background">
				<section id="about" className="about-mf sect-pt4 route">
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="row">
									<div className="col-md-6">
										{/* <DisplayLottie
											animationData={floating}
										/> */}
										<img
											src={headshot}
											alt="JustinHeadshot"
											className="headshot_image"
											style={{
												width: "450px",
												borderRadius: "50%",
												position: "relative",
												top: "12%",
												border: "2px solid #000000",
											}}
										/>
									</div>

									<div className="col-md-6">
										<div className="about-me pt-4 pt-md-0">
											<div className="title-box-2">
												<h1 className="leftUnderline topSpacing">
													About Me
												</h1>
											</div>
											{this.state.about_me.map(
												(content) => {
													return (
														<p
															className="lead"
															key={content.id}
														>
															{content.content}
														</p>
													);
												}
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default About;
